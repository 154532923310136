import CachedIcon from "@mui/icons-material/Cached";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MUIDrawer,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import {
  CrossIcon,
  IconComponents,
  LogoutIcon,
  UserNavIcon,
} from "components/svg";

import { useAppSelector } from "store/hook";

import {
  AA_AUTH_SERVICE_LOGOUT,
  AY_AUTH_SERVICE_LOGOUT,
  BA_AUTH_SERVICE_LOGOUT,
  BW_AUTH_SERVICE_LOGOUT,
  QF_AUTH_SERVICE_LOGOUT,
  REFERENCE_DATA_URL,
} from "utils/axios/endpoints";
import { IRoutes } from "utils/interface";

import { handleCache } from "api-services/user";

interface Props {
  openDraw: boolean;
  setOpenDraw: any;
}

function Drawer({ openDraw, setOpenDraw }: Props) {
  const {
    currentUser,
    accessibleRoutes,
    uploadedFileUser,
    isSignalReconnected,
    isSignalRProcessing,
    claimRight,
  } = useAppSelector((state: any) => state?.app);

  const handleLogOut = async () => {
    localStorage.removeItem("isSignalRConnectionDown");
    localStorage.removeItem("token");
    switch (currentUser.SystemId) {
      case "GRPQAU":
        window.location.href = QF_AUTH_SERVICE_LOGOUT;
        break;
      case "GRPAAA":
        window.location.href = AA_AUTH_SERVICE_LOGOUT;
        break;
      case "GRPAAY":
        window.location.href = AY_AUTH_SERVICE_LOGOUT;
        break;
      case "GRPBWW":
        window.location.href = BW_AUTH_SERVICE_LOGOUT;
        break;
      case "GRPABA":
        window.location.href = BA_AUTH_SERVICE_LOGOUT;
        break;
      default:
        break;
    }
    localStorage.setItem("isSignalReconnected", isSignalReconnected);
    localStorage.setItem("isSignalRProcessing", isSignalRProcessing);
    localStorage.setItem("uploadedFileUser", uploadedFileUser);
  };

  const handleClearCache = async () => {
    await handleCache();
    setOpenDraw(false);
  };

  const isCacheUri = claimRight?.ClaimRights?.ClaimResourceUrls.find(
    (item: { Uri: string }) => item.Uri === REFERENCE_DATA_URL,
  );

  return (
    <MUIDrawer
      anchor="right"
      open={openDraw}
      onClose={() => setOpenDraw(false)}
      id="rmt_drawer_wrapper"
    >
      <Box sx={{ width: 420 }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton sx={{ display: "flex", alignItems: "start" }}>
              <ListItemIcon sx={{ minWidth: "30px" }} id="rmt_drawer_user_icon">
                <UserNavIcon fill="black" width="25" height="25" />
              </ListItemIcon>
              <ListItemText
                sx={{ margin: "0px" }}
                primary={
                  <Typography id="rmt_user_firstLast_name" variant="body1">
                    {currentUser.FirstName}
                  </Typography>
                }
                secondary={
                  <Typography id="rmt_username" variant="body1">
                    {currentUser.Name}
                  </Typography>
                }
                id="rmt_username"
              />
              <Box
                component="span"
                onClick={() => setOpenDraw(false)}
                id="rmt_drawer_close"
              >
                <CrossIcon fill="red" width="25" height="25" />
              </Box>
            </ListItemButton>
          </ListItem>
          <Divider />
          {accessibleRoutes?.length > 0 &&
            accessibleRoutes?.map((item: IRoutes, index: number) => {
              const IconComponent = IconComponents[item.icon];

              return (
                <Link
                  key={`${index + 1}`}
                  onClick={() => setOpenDraw(false)}
                  style={{ textDecoration: "none", color: "#233543" }}
                  to={item.route}
                  id={`rmt_drawer_item_${index + 1}`}
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon
                        sx={{ minWidth: "30px" }}
                        id="rmt_airline_icon"
                      >
                        <IconComponent fill="black" width="25" height="25" />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Link>
              );
            })}
        </List>
        <Box>
          <ListItem
            disablePadding
            sx={{
              position: "absolute",
              bottom: 0,
              borderTop: "1px solid #b1bbc4",
            }}
          >
            <ListItemButton onClick={handleLogOut} id="rmt_logout">
              <ListItemIcon sx={{ minWidth: "30px" }} id="rmt-logout-icon">
                <LogoutIcon fill="black" width="25" height="25" />
              </ListItemIcon>
              <ListItemText primary="Log out" id="rmt-logout-text" />
            </ListItemButton>
          </ListItem>
          {isCacheUri?.Uri === REFERENCE_DATA_URL && (
            <ListItem
              disablePadding
              sx={{
                position: "absolute",
                bottom: 50,
                borderTop: "1px solid #b1bbc4",
              }}
            >
              <ListItemButton id="rmt_logout" onClick={handleClearCache}>
                <ListItemIcon sx={{ minWidth: "30px" }} id="rmt-logout-icon">
                  <CachedIcon fill="black" width="25" height="25" />
                </ListItemIcon>
                <ListItemText primary="Clear cache" id="rmt-logout-text" />
              </ListItemButton>
            </ListItem>
          )}
        </Box>
      </Box>
    </MUIDrawer>
  );
}

export default Drawer;
